import {QuillOptionsStatic} from "quill";
import AttachmentType from "@common/types/attachment-type";

export default {
    /**
     * 默认语言
     */
    defaultLocale : 'en_us',
    /**
     * 支持语言
     */
    availableLocales : ['zh_ch', 'en_us', 'zh-tw'],
    /**
     * 默认分页页码
     */
    defaultPageNumber : 1,
    /**
     * 默认分页每页记录数
     */
    defaultPageLimit : 1
};

export const globalQuillOptions : QuillOptionsStatic = {
    // debug : 'info',
    theme : 'snow'
};

export const STATUS_ERROR : number = 0;

export const STATUS_SUCCESS : number = 0;

/**
 * 系统支持附件类型定义
 */
export const ATTACHMENT_TYPES : { [key : string] : AttachmentType } = {
    /**
     * 未指定
     */
    UNSPECIFIED : {
        type : 'UNSPECIFIED',
        multiple : false,
        ext : [],
    },
    /**
     * 产品图片
     */
    PRODUCT_COVER : {
        type : 'PRODUCT_COVER',
        multiple : false,
        ext : ['png', 'jpeg', 'jpg', 'git'],
    },
    /**
     * 产品封面图片
     */
    PRODUCT_PICTURES : {
        type : 'PRODUCT_PICTURES',
        multiple : true,
        ext : ['png', 'jpeg', 'jpg', 'git'],
    },
    /**
     * 产品目录文件
     */
    PRODUCT_CATALOG_COVER : {
        type : 'PRODUCT_CATALOG_COVER',
        multiple : false,
        ext : ['png', 'jpeg', 'jpg', 'git'],
    },
    /**
     * 产品目录封面图片
     */
    PRODUCT_CATALOG_FILES : {
        type : 'PRODUCT_CATALOG_FILES',
        multiple : true,
        ext : ['png', 'jpeg', 'jpg', 'git'],
    },
    /**
     * 用户头像
     */
    USER_AVATAR : {
        type : 'USER_AVATAR',
        multiple : false,
        ext : ['png', 'jpeg', 'jpg', 'git'],
    },
};
