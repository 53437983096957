export default {
    site_title : 'Admin Console',
    site_copyright : 'Copyright',
    common_button_submit : 'Submit',
    common_button_confirm : 'Confirm',
    common_button_reset : 'Reset',
    common_button_back : 'Back',
    common_button_cancel : 'Cancel',
    common_button_ok : 'OK',
    common_button_yes : 'Yes',
    common_button_no : 'No',
    common_button_upload : 'Upload',
    common_button_login : 'Login',
    common_button_logout : 'Logout',
    common_button_register : 'Register',
    common_button_select_file : 'Select File',
    common_button_select_files : 'Select Files',
    common_button_start_upload : 'Start Upload',
    common_button_stop_upload : 'Stop Upload',
    common_button_search : 'Search',
    common_button_add : 'Add',
    common_button_detail : 'Detail',
    common_button_edit : 'Edit',
    common_button_delete : 'Delete',
    common_field_id : 'ID',
    common_field_code : 'Code',
    common_field_title : 'Title',
    common_field_content : 'Content',
    common_field_cover : 'Cover',
    common_field_avatar : 'Avatar',
    common_field_pictures : 'Pictures',
    common_field_file : 'File',
    common_field_files : 'Files',
    common_field_description : 'Description',
    common_field_status : 'Status',
    common_field_active : 'Active',
    common_field_tag : 'Tag',
    common_field_created_at : 'Created At',
    common_field_created_by : 'Created By',
    common_field_updated_at : 'Updated At',
    common_field_updated_by : 'Updated By',
    common_field_deleted_at : 'Deleted At',
    common_field_deleted_by : 'Deleted By',
    common_field_actions : 'Actions',
    common_label_status_on : 'On',
    common_label_status_off : 'Off',
    common_label_delete_item_confirm : 'Are you sure you want to delete this item?',
    common_label_delete_item_success : 'Item deleted successfully!',
    common_label_image_cropper : 'Cropper',
    user_field_username : 'Username',
    user_field_nickname : 'Nickname',
    user_field_fullname : 'Fullname',
    user_field_email : 'Email',
    user_field_password : 'Password',
    user_field_old_password : 'Old Password',
    user_field_new_password : 'New Password',
    user_field_confirm_password : 'Confirm Password',
    user_pages_list_title : 'User Management',
    user_pages_detail_title : 'User Detail',
    user_pages_add_title : 'Add User',
    user_pages_edit_title : 'Edit User',
    user_pages_profile_title : 'Profile',
    user_pages_account_title : 'Account',
    user_pages_change_password_title : 'Change Password',
    user_pages_login_title : 'Login',
    tag_pages_list_title : 'Tag Management',
    tag_pages_detail_title : 'Tag Detail',
    tag_pages_add_title : 'Add Tag',
    tag_pages_edit_title : 'Edit Tag',
    product_catalog_pages_list_title : 'Product Catalog Management',
    product_catalog_pages_detail_title : 'Product Catalog Detail',
    product_catalog_pages_add_title : 'Add Product Catalog',
    product_catalog_pages_edit_title : 'Edit Product Catalog',
    product_pages_list_title : 'Product Management',
    product_pages_detail_title : 'Product Detail',
    product_pages_add_title : 'Add Product',
    product_pages_edit_title : 'Edit Product',
};
