




































import {Component, Vue} from 'vue-property-decorator';
import {isEmpty} from 'lodash';
import ProductCatalogService from '@common/services/site/product-catalog.service';
import Page from "@common/types/page.interface";
import DataList, {processData, processParams} from "@common/types/data-list.interface";
import {images} from '@site/constants';
import ProductCatalogEntity from "@common/types/product-catalog.entity";
import {formatUrl} from "@common/utils";

@Component({})
export default class ProductCatalog extends Vue {
    //
    dataList : DataList = new DataList();
    //
    productCatalogCover = images.product_catalog;

    get navItems() : any {
        return [{
            text : this.$i18n.t('common_label_home'),
            disabled : false,
            href : '#/'
        }, {
            text : this.$i18n.t('common_label_product_catalog'),
            disabled : true,
            href : '#/product-catalog'
        }]
    }

    fetchData() : Promise<Page> {
        return ProductCatalogService.search(processParams(this.dataList)).then((response : any) => {
            const page : Page = response.data as Page;
            processData(this.dataList, page);
            return page;
        });
    }

    getUrl(item : ProductCatalogEntity) {
        if (item && item.files && item.files.fileList && !isEmpty(item.files.fileList)) {
            return formatUrl(item.files.fileList[0].url)
        }
        return '';
    }

    infiniteHandler($state : any) {
        this.fetchData().then((page : Page) => {
            if (page.last_page === 1 || isEmpty(page.data)) {
                $state.complete();
            } else {
                $state.loaded();
            }
        });
    }

}
