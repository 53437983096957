import Vue from 'vue';
import VueI18n from 'vue-i18n';
import constants from '@common/utils/constants';
import enUs from '@common/locales/en_us';
import zhCn from '@common/locales/zh_cn';
import zhTw from '@common/locales/zh_tw';

Vue.use(VueI18n);

export default new VueI18n({
    locale : constants.defaultLocale,
    fallbackLocale : constants.defaultLocale,
    messages : {
        en_us : enUs,
        zh_ch : zhCn,
        zh_tw : zhTw
    }
});
