export const images = {
    logo : require('./assets/images/logo.png'),
    product : require('./assets/images/product.jpg'),
    product_catalog : require('./assets/images/product-catalog.png'),
    home : {
        home_1 : require('./assets/images/home-1.jpeg'),
        home_2 : require('./assets/images/home-2.jpeg'),
        home_3 : require('./assets/images/home-3.jpeg'),
    }
};
