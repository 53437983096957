













































import {Component, Vue} from 'vue-property-decorator';
import ProductEntity from "@common/types/product.entity";
import ProductService from '@common/services/site/product.service';
import Result from "@common/types/result.interface";
import AttachmentFileEntity from "@common/types/attachment-file.entity";
import {formatUrl} from "@common/utils";

@Component({})
export default class ProductDetail extends Vue {
    // 实体
    entity : ProductEntity = new ProductEntity();
    // 图片
    images : string[] = [];
    //
    carouselActiveIndex : number = 0;

    get id() : any {
        return this.$route.params['id'] ? this.$route.params['id'] : 0;
    }

    get navItems() : any {
        return [{
            text : this.$i18n.t('common_label_home'),
            disabled : false,
            href : '#/'
        }, {
            text : this.$i18n.t('common_label_product'),
            disabled : false,
            href : '#/product'
        }, {
            text : this.$i18n.t('common_label_product_detail'),
            disabled : true,
            href : '#/product-detail'
        }]
    }

    changeCarouselActiveIndex(i : number) {
        console.log(i);
        this.carouselActiveIndex = i;
    }

    mounted() {
        ProductService.detail({id : this.id}).then((response : any) => {
            const result = response.data as Result;
            this.entity = result.data as ProductEntity;
            if (this.entity && this.entity.pictures && this.entity.pictures.fileList) {
                this.images = this.entity.pictures.fileList.map((file : AttachmentFileEntity) => {
                    return formatUrl(file.url);
                })
            }
        });
    }

}
