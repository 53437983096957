import Environment from "@common/types/environment.interface";

/**
 * 生产环境配置
 *
 * @type Environment
 */
const env : Environment = {
    /**
     * 模式名称
     */
    mode : 'production',
    /**
     * 是否生产模式
     */
    production : true,
    /**
     * 是否启用XDebug
     */
    enableXDebug : false,
    /**
     * 服务器地址
     */
    server : 'http://www.timsenaudio.com'
};

export default env;
