//
import Vue from 'vue';
import Vuetify from 'vuetify';
import Meta from 'vue-meta';
//
import InfiniteLoading from 'vue-infinite-loading';
// fontawesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
//
import App from '@site/components/App.vue';
import i18n from '@site/i18n';
import router from '@site/routes';
import store from '@site/stores';
//
import '@site/main.scss';
// fontawesome
library.add(fas, fab, far);
//
Vue.config.productionTip = false;
// vue-meta
Vue.use(Meta);
// fontawesome
Vue.component('font-awesome-icon', FontAwesomeIcon);
// vue-infinite-loading
Vue.component('vue-infinite-loading', InfiniteLoading);
// vuetify
Vue.use(Vuetify, {
    iconfont : 'fa'
});
new Vue({
    router,
    store,
    i18n,
    render : (h : any) => h(App)
}).$mount('#app');
