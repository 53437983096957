import axios from "@common/utils/request";

export default {
    search(params : any) {
        return axios.post('/api/product/search', params);
    },
    detail(params : any) {
        return axios.post('/api/product/detail', params);
    }
}
