import TagEntity from "@common/types/tag.entity";

export default class TagRelation {
    /**
     * 目标实体
     */
    targetId? : number | string = 0;
    /**
     * 目标类型
     */
    targetType? : string = '';
    /**
     * 标签ID数组
     */
    tagIdList? : number[] = [];
    /**
     * 标签数组
     */
    tagList? : TagEntity[] = [];
    /**
     * 是否正在加载搜索
     */
    loading? : boolean = false;
}
