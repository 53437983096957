import {format} from "date-fns";
import {decode} from "jsonwebtoken";
import {JwtPayload} from "@common/types/jwt-payload.interface";
import environment from "@common/environments/environment";

/**
 * 解析JWT获取用户信息
 */
export const parseJwtToken = (token : string) : JwtPayload | null => {
    if (token) {
        const payload : JwtPayload = decode(token) as JwtPayload;
        if (payload) {
            return payload;
        }
    }
    return null;
};

/**
 * 格式化日期
 */
export function formatDatetime(date : any) {
    if (date !== undefined) {
        return format(date, "YYYY-MM-DD HH:mm")
    }
    return '--';
}

/**
 * 格式化日期
 */
export function formatDate(date : any) {
    if (date !== undefined) {
        return format(date, "YYYY-MM-DD")
    }
    return '--';
}

/**
 * 格式化年份
 */
export function formatYear(date : any) {
    if (date !== undefined) {
        return format(date, "YYYY")
    }
    return '--';
}

/**
 * 从文件对象获取本地图片链接
 */
export function getObjectURL(file : File) {
    let url = null;
    if (URL !== undefined) {
        url = URL.createObjectURL(file);
    }
    return url;
}

/**
 * 处理链接
 */
export function getCoverUrl(url : any) {
    return environment.server + url;
}

/**
 * 处理链接
 */
export function formatUrl(url : any) {
    return environment.server + url;
}
