
























































































import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class About extends Vue {
    get navItems() : any {
        return [{
            text : this.$i18n.t('common_label_home'),
            disabled : false,
            href : '#/'
        }, {
            text : this.$i18n.t('common_label_about_us'),
            disabled : true,
            href : '#/about'
        }]
    }
}
