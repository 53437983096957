






































import {Component, Vue} from 'vue-property-decorator';
import ProductService from '@common/services/site/product.service';
import TagService from '@common/services/site/tag.service';
import {isEmpty} from 'lodash';
import Page from "@common/types/page.interface";
import DataList, {processData, processParams} from "@common/types/data-list.interface";
import {images} from '@site/constants';
import Result from "@common/types/result.interface";
import TagEntity from "@common/types/tag.entity";
import ProductEntity, {getProductCover} from "@common/types/product.entity";

@Component({})
export default class Product extends Vue {
    /**
     * 产品列表
     */
    dataList : DataList = new DataList();
    /**
     * 分类标签
     */
    tagList : TagEntity[] = [];
    /**
     * 当前选中标签
     */
    tagId : number = 0;
    /**
     * 产品列表
     */
    product = images.product;

    get navItems() : any {
        return [{
            text : this.$i18n.t('common_label_home'),
            disabled : false,
            href : '#/'
        }, {
            text : this.$i18n.t('common_label_product'),
            disabled : true,
            href : '#/product'
        }]
    }

    searchByTagId(id : number) : void {
        this.tagId = id;
        this.dataList = new DataList();
        this.fetchData().then();
    }

    fetchData() : Promise<Page> {
        return ProductService.search(processParams(this.dataList, {
            tagId : this.tagId
        })).then((response : any) => {
            processData(this.dataList, response.data as Page);
            return response.data as Page;
        });
    }

    infiniteHandler($state : any) {
        this.fetchData().then((page : Page) => {
            if (page.last_page === 1 || isEmpty(page.data)) {
                $state.complete();
            } else {
                $state.loaded();
            }
        });
    }

    gotoDetail(id : any) {
        this.$router.push(`/product-detail/${id}`);
    }

    getCoverUrl(item : ProductEntity) {
        return getProductCover(item);
    }

    mounted() {
        TagService.list().then((response : any) => {
            const result = response.data as Result;
            this.tagList = result.data;
        });
    }

}
