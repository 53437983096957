import axios, {AxiosRequestConfig} from 'axios';
import {isArray, isEmpty, merge} from 'lodash';
//
import environment from '@common/environments/environment';
import storageService from '@common/services/storage.service';

// 文件上传的表单头信息
const multipartHeaders : AxiosRequestConfig = {
    headers : {
        'Content-Type' : 'multipart/form-data'
    },
    method : 'post',
};

//
axios.defaults.timeout = 60000;
axios.defaults.headers.post['Content-Type'] = 'application/x-platform-www-form-urlencoded';
axios.defaults.baseURL = environment.server;

/**
 * 启用XDebug附带参数
 */
if (environment.enableXDebug) {
    console.log('xdebug enabled.');
    axios.defaults.params = {
        XDEBUG_SESSION_START : 'XDebugIDE'
    };
}

/**
 * XDebug
 */
axios.interceptors.request.use(
    async (config : AxiosRequestConfig) => {
        if (environment.enableXDebug) {
            if (config.params) {
                if (isArray(config.params)) {
                    config.params = merge(config.params, {
                        XDEBUG_SESSION_START : 'XDebugIDE'
                    });
                }
            } else {
                config.params = {
                    XDEBUG_SESSION_START : 'XDebugIDE'
                };
            }
            console.log(config);
        }
        return config;
    },
    async (error : any) => {
        return Promise.reject(error);
    }
);
/**
 * Token
 */
axios.interceptors.request.use(
    async (config : any) => {
        const token = await storageService.getToken();
        if (isEmpty(token)) {
            config.headers.common['Authorization'] = null;
        } else {
            config.headers.common['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    async (error : any) => {
        return Promise.reject(error);
    }
);
/**
 * Result
 */
axios.interceptors.response.use(
    (response : any) => {
        return response;
    },
    (error : any) => {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    console.log(error.response.data.message);
                    break;
                case 401:
                    break;
                default:
                    console.log(error.response);
                    break;
            }
        } else {
            console.log(error);
        }
        return Promise.reject(error);
    }
);

const getUrl = (url : string) => {
    return environment.server + url;
};

export default axios;

export {getUrl, multipartHeaders};
