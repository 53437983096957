



































































































































import {Component, Vue} from 'vue-property-decorator';
import {images} from '@site/constants';
import ProductEntity, {getProductCover} from "@common/types/product.entity";
import ProductService from '@common/services/site/product.service';
import Page from "@common/types/page.interface";

@Component({})
export default class Home extends Vue {
    //
    images = images.home;
    //
    logo = images.logo;
    /**
     * 首页推荐产品
     */
    products : ProductEntity[] = [];

    fetchData() {
        ProductService.search({limit : 6}).then((response : any) => {
            const page = response.data as Page;
            this.products = page.data as ProductEntity[];
        });
    }

    getCoverUrl(item : ProductEntity) {
        return getProductCover(item);
    }

    gotoDetail(id : any) {
        this.$router.push(`/product-detail/${id}`);
    }

    mounted() {
        this.fetchData();
    }
}
