<template>
    <v-app>
        <v-toolbar flat color="brown darken-1" app dark class="elevation-0">
            <v-toolbar-title>Timsen Audio</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn flat to="/home">Home</v-btn>
                <v-btn flat to="/product">Product</v-btn>
                <v-btn flat to="/product-catalog">Product Catalog</v-btn>
                <v-btn flat to="/about">About us</v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-content>
            <router-view></router-view>
        </v-content>

        <v-footer height="200px" dark>
            <v-layout row wrap align-center>
                <v-flex xs12>
                    <div class="text-xs-center my-2">
                        &copy;2003-2019 Timsen Development Limited
                    </div>
                    <div class="text-xs-center my-2">
                        All Rights Reserved.
                    </div>
                </v-flex>
            </v-layout>
        </v-footer>
    </v-app>
</template>

<script type="text/javascript">
    import {Component, Vue} from 'vue-property-decorator';

    @Component()
    export default class SiteLayout extends Vue {
    }
</script>
