import AttachmentType from "@common/types/attachment-type";
import AttachmentFileEntity from "@common/types/attachment-file.entity";
import {ATTACHMENT_TYPES} from "@common/utils/constants";

export default class AttachmentEntity {
    /**
     * 目标实体
     */
    targetId? : number | string = 0;
    /**
     * 目标类型
     */
    targetType? : string = '';
    /**
     * 附件类型
     */
    attachmentType? : AttachmentType = ATTACHMENT_TYPES.UNSPECIFIED;
    /**
     * 附件文件ID列表
     */
    fileIdList? : number[] = [];
    /**
     * 附件文件列表
     */
    fileList? : AttachmentFileEntity[] = [];

    constructor(targetType : string = '') {
        this.targetType = targetType;
    }

}
