import * as localforage from "localforage";

/**
 * config
 */
localforage.config({
    name : 'react',
    driver : localforage.LOCALSTORAGE
});

/**
 * Keys
 * @type {{token: string}}
 */
export const keys : { token : string } = {
    token : 'token'
};

/**
 * 存储服务
 */
export default {
    //
    getToken() : Promise<any> {
        return this.getItem(keys.token);
    },
    //
    setToken(val : string) : Promise<void> {
        if (val) {
            return this.setItem(keys.token, val);
        } else {
            return this.removeItem(keys.token);
        }
    },
    //
    getItem(key : string) {
        return localforage.getItem(key);
    },
    //
    removeItem(key : string) {
        return localforage.removeItem(key);
    },
    //
    setItem(key : string, val : any) {
        if (val) {
            return localforage.setItem(key, val);
        } else {
            return this.removeItem(key);
        }
    },
    /**
     * 清空存储
     */
    clear() {
        return localforage.clear();
    },
}
