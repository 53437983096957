import Vue from 'vue';
import VueRouter from 'vue-router';
import SiteLayout from "@site/components/Layout/SiteLayout.vue";
import Home from '@site/views/Home.vue';
import About from '@site/views/About.vue';
import ProductCatalog from "@site/views/ProductCatalog.vue";
import Product from "@site/views/Product.vue";
import ProductDetail from "@site/views/ProductDetail.vue";

Vue.use(VueRouter);

export default new VueRouter({
    routes : [{
        path : '/',
        component : SiteLayout,
        children : [{
            path : '/',
            name : 'index',
            component : Home,
        }, {
            path : '/home',
            name : 'home',
            component : Home,
        }, {
            path : '/about',
            name : 'about',
            component : About,
        }, {
            path : '/product',
            name : 'product',
            component : Product,
        }, {
            path : '/product-detail/:id',
            name : 'product-detail',
            component : ProductDetail,
        }, {
            path : '/product-catalog',
            name : 'product-catalog',
            component : ProductCatalog,
        }]
    }]
})
